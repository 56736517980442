#slider-header {
	position: relative;
	width: 100%;
	
	@media(max-width: 1200px) {
		//margin-top: 50px;
	}
	
	.carousel-inner {
		
		.carousel-item {
			height: 990px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top 0 right 0;
			
			@media(max-width: 1200px) {
				background-position: top 0 right 15%;
			}
			
			@media(max-width: 1190px) {
				&:nth-child(2) {
					background-position: top 0 right 40%;
				}
				&:nth-child(3) {
					background-position: top 0 right 35%;
				}
			}
			
			.carousel-caption {
				z-index: 1;	
				font-family: $main-alternate-font-family;
				font-weight: 300;
				text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
				@include addFontSizeLineHeightPxToRem(25, 30, 60);
				text-transform: uppercase;
				margin-bottom: 10%;
				@media(max-width: 1200px) {
					margin-bottom: pixelsToRem(50);
				}
				.h1 {
					@include addFontSizeLineHeightPxToRem(90, 110, 50);
					font-weight: 300;
					margin-bottom: pixelsToRem(30);
					@media(max-width: 1200px) {
						font-size: pixelsToRem(50);
						margin-bottom: pixelsToRem(10);
					}
					@media(max-width: 600px) {
						//font-size: pixelsToRem(25);
					}
				}
			}
			
		}
	}
	
	.carousel-indicators {
		[data-bs-target] {
			width: 146px;
			height: 2px;
			margin-right: 5px;
			margin-left: 5px;
			border: 2px solid #fff;
			background-color: #fff;
			opacity: 1;
			box-shadow: 0px 0px 2px 0px rgba(0,0,0,.2);
			@media(max-width: 1200px) {
				max-width: 30%;
			}
		}
		
		.active {
			background-color: $main-primary-color;
			width: 146px;
			height: 2px;
			@media(max-width: 1200px) {
				max-width: 30%;
			}
		}
		
		@media(max-width: 767px) {
			//display: none;
		}
	}
	
	.carousel-control-prev, .carousel-control-next {
		opacity: 1;
		&:hover {
			.carousel-control-prev-icon::before, .carousel-control-next-icon::before {
				color: $main-secondary-color;
				text-shadow: none;
			}
		}
	}
	
	.carousel-control-prev .carousel-control-prev-icon {
		background-image: none;
		&::before {
			font-size: pixelsToRem(40);
			content: "\F284";
			font-family: "bootstrap-icons";
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
		}
	}
	
	.carousel-control-next .carousel-control-next-icon {
		background-image: none;
		&::before {
			content: "\F285";
			font-family: "bootstrap-icons";
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
			font-size: pixelsToRem(40);
		}
	}
	
}