p {
	@include pxToRemWithRwd(margin-bottom, 10);
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	&:hover, &:focus {
		text-decoration: underline;
	}
}

.main-primary-color-text {
	color: $main-primary-color !important;
}

.main-primary-color-bg {
	background-color: $main-primary-color !important;
}

.main-secondary-color-text {
	color: $main-secondary-color !important;
}

.main-secondary-color-bg {
	background-color: $main-secondary-color !important;
}


.container {
	max-width: $container-body-max-width;
}

.main-max-one {
	max-width: 740px;
}

.h2 {
	font-family: $main-alternate-font-family;
	@include addFontSizeLineHeightPxToRem(30, 37, 90);
	text-align: center;
	margin-bottom: pixelsToRem(30);
}