header#main-header {
	background-color: #dde0e5;
	
	#top-bar {
		position: absolute;
		z-index: 11;
		top: 0;
		left: calc(50% - ($container-body-max-width/2));
		width: $container-body-max-width;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: pixelsToRem(20) pixelsToRem(13.5);
		
		@media(max-width: $container-body-max-width) {
			left: 0;
			width: 100%;
		}
		
		@media(max-width: 1200px) {
			padding: 0;
			position: fixed;
  			z-index: 10000;
			background-color: transparent;
			//box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
		}
		
		#logo-container {
			@media(max-width: 1200px) {
				position: absolute;
				top: 5px;
				left: 5px;
			}
		}
		
		&.top-bar-sticky {
			position: fixed;
			background-color: #fff;
			left: 0;
			width: 100%;
			padding-left: pixelsToRem(30);
			padding-right: pixelsToRem(30);
			box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
			@media(max-width: 1200px) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		#langs {
			position: absolute;
			top: pixelsToRem(20);
			right: pixelsToRem(13.5);
			@media(max-width: 1200px) {
				top: 7px;
				right: pixelsToRem(13.5);
			}
			.btn {
				padding: 0;
			}
			a {
				font-size: pixelsToRem(14);
				text-decoration: none;
				&:hover, &:focus {
					text-decoration: none;
				}
			}
		}
	}
	
}