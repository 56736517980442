.main-section {
	
	margin-top: pixelsToRem(60);
	margin-bottom: pixelsToRem(60);
	
	&.section-type-a {
		padding-top: pixelsToRem(280);
		padding-bottom: pixelsToRem(200);
		@include addBackground($bgColor: transparent, 
			$bgImage: url("../precompile/bg-sec-type-a.jpg"), 
			$bgRepeat: no-repeat, 
			$bgSize: calc(100% - (100% - ($container-body-max-width - 27px))), 
			$bgPosition: center
		);
		.main-content {
			@media(max-width: 1199px) {
				background-color: rgba(255, 255, 255, .8);
				padding: pixelsToRem(15);
			}
		}
	}
	
	&.section-contact {
		.main-content {
			max-width: 740px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	&.section-video {
		@media(max-width: 767px) {
			.h2 {
				display: none;
			}
		}
	}
	
}