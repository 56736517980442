.main-products {
	.item {
		text-align: center;
		margin-bottom: pixelsToRem(30);
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: pixelsToRem(15);
		}
		.h3 {
			@include addFontSizeLineHeightPxToRem(13, 24);
			color: $main-alternate-color;
			font-weight: 400;
		}
	}
}